<template>
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <a-menu-item @click="toggleCollapsed" key="dashboard">
      <router-link to="/">
        <sdFeatherIcons type="home" />
        <span> Dashboard </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="virtual-stock">
      <router-link to="/purchase/virtual-stock">
        <sdFeatherIcons type="inbox" />
        <span > Virtual Stock </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="inspection-rapport">
      <router-link to="/purchase/inspection-rapport">
        <sdFeatherIcons type="archive" />
        <span > Inspection Rapport </span>
      </router-link>
    </a-menu-item> 
    <a-menu-item @click="toggleCollapsed" key="transport-rapport">
      <router-link to="/purchase/transport-rapport">
        <sdFeatherIcons type="archive" />
        <span > Transport Rapport </span>
      </router-link>
    </a-menu-item> 
    <a-sub-menu key="purchase">
      <template v-slot:title
        ><sdFeatherIcons type="layers" /><span>Purchase</span>
      </template>
      <a-menu-item @click="toggleCollapsed" key="purchase-orders">
        <router-link to="/purchase/purchase-orders"> Orders to suplliers</router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="sale">
      <template v-slot:title
        ><sdFeatherIcons type="trending-up" /><span>Sale</span></template
      >
      <a-menu-item @click="toggleCollapsed" key="sale/1">
        <router-link to="/sale/listing/1"> Sale Offer  </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="sale/2">
        <router-link to="/sale/listing/2"> Clients Orders </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="sale/3">
        <router-link to="/sale/listing/3"> Invoices </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="finances">
      <template v-slot:title
        ><sdFeatherIcons type="dollar-sign" /><span>Finances</span></template
      >
      <a-menu-item @click="toggleCollapsed" key="finances/bank-operations">
        <router-link to="/finances/bank-operations">
          Bank Operations
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="finances/expenses-operations">
        <router-link to="/finances/expenses-operations">
          Operational Costs
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="finances/cash-operations">
        <router-link to="/finances/cash-operations">
          Cash Operations
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="finances/leasing-operations">
        <router-link to="#"> Leasing Operations </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="finances-setting">
      <template v-slot:title
        ><sdFeatherIcons type="command" /><span
          >Finances Settings</span
        ></template
      >
      <a-menu-item @click="toggleCollapsed" key="finances-setting/cash">
        <router-link to="/finances-setting/cash"> Cash </router-link>
      </a-menu-item>
      <a-menu-item
        @click="toggleCollapsed"
        key="/finances-setting/cash-operations-type"
      >
        <router-link to="/finances-setting/cash-operations-type">
          Cash Operation Type
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="finances-setting/bank">
        <router-link to="/finances-setting/bank"> Banks </router-link>
      </a-menu-item>
      <a-menu-item
        @click="toggleCollapsed"
        key="/finances-setting/bank-operations-type"
      >
        <router-link to="/finances-setting/bank-operations-type">
          Expense Type
        </router-link>
      </a-menu-item>
      <a-menu-item
        @click="toggleCollapsed"
        key="/finances-setting/payment-method"
      >
        <router-link to="/finances-setting/payment-method">
          Payment Method
        </router-link>
      </a-menu-item>
      <a-menu-item
        @click="toggleCollapsed"
        key="/finances-setting/payment-term-options"
      >
        <router-link to="/finances-setting/payment-term-options">
          Payment Term options
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="finances-setting/accounts">
        <router-link to="/finances-setting/accounts"> Accounts </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="Partners">
      <router-link to="/Partners">
        <sdFeatherIcons type="user-check" />
        <span> Partners </span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="Users">
      <router-link to="/Users">
        <sdFeatherIcons type="users" />
        <span> Users </span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="supplier">
      <router-link to="/supplier">
        <sdFeatherIcons type="truck" />
        <span> Supplier </span>
      </router-link>
    </a-menu-item>
    <a-sub-menu key="settings">
      <template v-slot:title
        ><sdFeatherIcons type="key" /><span>Settings</span></template
      >
      <a-menu-item @click="toggleCollapsed" key="category">
        <router-link to="/settings/category"> Category </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="transport-method">
        <router-link to="/settings/transport-method"> Transport Method </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="thread">
        <router-link to="/settings/thread"> Thread </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="grade">
        <router-link to="/settings/grade"> Grade </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="inspectionSOW">
        <router-link to="/settings/inspectionSOW"> Inspection SOW </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="hascode">
        <router-link to="/settings/hscode"> HSCode </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="incoterm">
        <router-link to="/settings/incoterm"> Incoterm </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="range">
        <router-link to="/settings/range"> Range </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="psl">
        <router-link to="/settings/psl"> PSL </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="units">
        <router-link to="/settings/units"> Units of Measurement </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="size">
        <router-link to="/settings/size"> Size </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="weight">
        <router-link to="/settings/weight"> Weight </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="activities">
        <router-link to="/settings/activities"> Activities </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="lineproduct">
        <router-link to="/settings/lineproduct">Product Line </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="currency">
        <router-link to="/settings/currency"> Currency </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>
<script>
import { computed, reactive, ref, toRefs, watch, watchEffect } from "vue";
import VueTypes from "vue-types";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import versions from "../demoData/changelog.json";

export default {
  name: "AsideItems",
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref("inline");
    const { events } = toRefs(props);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value;

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ["key"],
      openKeys: ["settings", "purchase", "sale"],
      preOpenKeys: ["settings", "purchase", "sale"],
    });

    watchEffect(() => {
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          state.selectedKeys = [router.value.matched[2].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else {
          state.selectedKeys = [router.value.matched[1].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        }
      }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      versions,
    };
  },
};
</script>
